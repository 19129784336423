<template>
    <div class="login-container">
      <h2>Login</h2>
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="text" id="email" v-model="email" required />
        </div>
        
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" required />
        </div>
  
        <div class="button-container">
          <button type="submit">Login</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import { useAuthStore } from '../stores/auth'; // Import the auth store
  
  export default {
    name: 'UserLogin',
    data() {
      return {
        email: '',
        password: ''
      };
    },
    setup() {
      const authStore = useAuthStore(); // Access the Pinia store
  
      return { authStore };
    },
    methods: {
    async handleLogin() {
      try {
        const response = await fetch('https://api.fastwrap.io/user/v1/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            email: this.email,
            password: this.password
          }),
        });

        const data = await response.json();

        if (data.email) {
          this.authStore.login({ email: this.email }); // Update the auth state
          this.$router.push('/dashboard'); // Redirect to dashboard
        } else {
          alert('Login failed, please try again.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred during login.');
      }
    }
  }
};
  </script>
  
  <style scoped>
  .login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 10px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    background-color: #42b983;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #888;
    cursor: not-allowed;
  }
  </style>
  